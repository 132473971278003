/** ----- RESET ----- */

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/** ----- GENERAL COMPONENTS ----- */

.scrollbar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
	background-color: #fafafa;
}

.scrollbar::-webkit-scrollbar-thumb {
	background-color: #ddd;
	border-radius: 6px;
}

.invisible-scrollbar::-webkit-scrollbar {
	width: 0;
	height: 0;
}
